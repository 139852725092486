.family-details-wrapper {
  display: flex;
  align-items: flex-start;
  gap: 28px;
  flex-direction: column;
  padding-left: 21px;

  .parents-names {
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
    .father-heading {
      display: flex;
      gap: 4px;

      .father {
        height: 12px;
        width: 12px;
      }
    }

    .mother-heading {
      display: flex;
      gap: 4px;

      .mother {
        height: 12px;
        width: 12px;
      }
    }

    h6 {
      color: var(--Dark, #194459);
      font-family: Noto Sans;
      font-size: 10px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: 1.5px;
      margin-bottom: 8px;
    }

    input {
      width: 396px;
      height: 40px;
      flex-shrink: 0;
      border-radius: 12px;
      border: 1px solid #98b3c1;
      background: #fff;
      padding-left: 10px;
    }
  }

  .siblings {
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
    .brother-heading {
      display: flex;
      gap: 4px;

      .brother {
        height: 12px;
        width: 12px;
      }
    }

    .sister-heading {
      display: flex;
      gap: 4px;

      .sister {
        height: 12px;
        width: 12px;
      }
    }

    h6 {
      color: var(--Dark, #194459);
      font-family: Noto Sans;
      font-size: 10px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: 1.5px;
      margin-bottom: 8px;
    }

    input {
      width: 396px;
      height: 40px;
      flex-shrink: 0;
      border-radius: 12px;
      border: 1px solid #98b3c1;
      background: #fff;
      padding-left: 10px;
    }
  }
}
