.notification-wrapper {
  font-family: Noto Sans;
  z-index: 1;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;

  .notification-maindiv {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    width: 396px;
    height: 477px;
    flex-shrink: 0;
    border-radius: 8px;
    border: 1px solid #becfd8;
    background: #fff;
    position: relative;
    right: 30px;
    top: 26px;
    cursor: pointer;

    .notification-header {
      display: flex;
      width: 100%;
      justify-content: space-between;
      align-items: center;
      margin-top: 20px;

      .notification-heading {
        color: #000;
        font-family: Noto Sans;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        letter-spacing: 0.5px;
      }

      .charmCross-icon {
        position: relative;
        right: 0px;
      }
    }

    .notification-content {
      display: flex;
      justify-content: space-evenly;
      flex-direction: column;
      margin: 0px 30px;

      .renderdiv {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 15px 0px;
        gap: 12px;
        border-bottom: 1px solid #5b686e;

        .notification-imgdiv {
          width: 60px;
          height: 60px;
          background: #d9d9d9;
          position: relative;
          bottom: 9px;
          display: flex;
          justify-content: center;
          align-items: center;

          img {
            width: 100%;
            height: 100%;
            object-fit: contain;
          }
        }

        .notification-data {
          width: 75%;
          text-align: left;
          display: flex;
          justify-content: center;
          flex-direction: column;
          gap: 4px;

          h4 {
            overflow: hidden;
            text-overflow: ellipsis;
            color: var(--Light, #5b686e);
            font-family: Noto Sans;
            font-size: 16px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            letter-spacing: 0.5px;
          }

          .desc {
            overflow: hidden;
            text-overflow: ellipsis;
            color: var(--Dark, #194459);
            font-family: Noto Sans;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            letter-spacing: 0.5px;
          }
        }
      }
    }
  }
}