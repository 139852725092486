@import "../../components/styles/mixin";
@import "../../components/styles/var";

.gallery-main-div {
  background-color: white;
  margin: 20px;
  display: flex;
  flex-direction: column;
  border-radius: 12px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  margin-bottom: 20px;
  min-height: 65vh;

  .head-wrapper {
    display: flex;
    justify-content: space-between;
    color: $background_blue;
    text-align: center;
    font-family: Noto Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 1.25px;
    padding-top: 20px;
    padding-left: 30px;
    padding-bottom: 30px;
    padding-right: 20px;

    .gallery-roles {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      width: 50%;
      a {
        margin-right: 35px;
        position: relative;
        color: $background_blue;

        &.active::after {
          content: "";
          position: absolute;
          width: 100%;
          height: 4px;
          background-color: $background_blue;
          bottom: -5px;
          left: 0;
        }
      }
      h3 {
        color: black;
      }
    }
  }

  .gallery-search-wrapper {
    display: flex;
    .text-area {
      outline: none;
      height: 36px;
      border: none;
      font-family: Noto Sans;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: 1.5px;
    }

    .add-btn {
      padding: 10px 32px;
      background: $background_blue;
      color: $background_white;
      border-radius: 12px;
      color: #fff;
      text-align: center;
      font-family: Noto Sans;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      letter-spacing: 1.25px;
      border: 0px solid black;
      margin-right: 15px;
    }
  }

 
.gallery-data-list {
    color: #000;
    font-family: Noto Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.5px;
    padding: 0 30px;
    min-height: 50vh;
  }
  
  .gallery-data-list>table {
    width: 100%;
    table-layout: auto;
  }
  
  table>thead {
    color: #666;
    font-family: Noto Sans;
    font-size: 10px;
  }
  
  thead>.head {
    padding: 0 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 1.5px;
  }
  
  .head>th {
    padding-bottom: 15px;
    color: #666;
    border-bottom: 2px solid #d9d9d9;
    font-family: Noto Sans;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 1.5px;
    text-align: left;
  }
  
  table>tbody>tr>td {
    padding: 12px 0;
  }
  
  
  .input-data-icon {
    display: flex;
    justify-content: flex-start;
    width: 100%;
  }
  
  .input-data-icon>img {
    padding: 0 5px;
  }
  
  input::placeholder {
    color: #98b3c1;
    font-family: Noto Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.5px;
  }
  
  .search-icon {
    width: 20px;
    height: 20px;
    margin: 0 10px;
  }
  
  .rectangle-group {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border-radius: 12px;
    border: 1px solid #98b3c1;
    background: #fff;
    width: 274px;
    height: 40px;
    margin-right: 12px;
  }
}
