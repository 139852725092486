.dashboard {
  display: flex;
  flex-direction: column;
}

.stats-counter {
  display: flex;
  padding: 20px 0;
  justify-content: space-around;
}

.count-div {
  display: flex;
  width: 48%;
  justify-content: space-around;
  padding: 20px;
  background: #fff;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  border-radius: 12px;

  .counter-card {
    display: flex;
    flex-direction: column;
    text-align: center;

    span {
      font-size: 32px;
      letter-spacing: 0.25;
      font-family: Noto Sans;
      font-weight: 700;
      line-height: normal;
    }

    h4 {
      font-size: 16px;
      letter-spacing: 0.25;
      font-family: Noto Sans;
      font-weight: 400;
      line-height: normal;
      text-transform: capitalize;
    }
  }
}

.youth-listing {
  display: flex;
  padding-bottom: 10px;
  justify-content: space-around;

  .youth-listing-wrapper {
    display: inline-block;
    width: 58%;
    display: flex;
    flex-direction: column;
    background-color: aliceblue;
    border-radius: 12px;
    background: #fff;
    padding: 10px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  }

  .birthday-listing-wrapper {
    display: inline-block;
    width: 38%;
    padding: 10px;
    display: flex;
    flex-direction: column;
    background-color: aliceblue;
    border-radius: 12px;
    background: #fff;
  }

  .heading {
    display: flex;
    justify-content: space-between;
    color: #194459;
    font-family: Noto Sans;
    font-size: 15px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.5px;
    margin-top: 14px;
    padding: 0 14px;
  }

  .data-list {
    padding: 20px 15px;
    overflow: auto !important;
    height: 270px !important;
    .name-cell {
      display: flex;
  
      .ellipse {
        height: 25px;
        width: 25px;
        background-color: #194459;
        border-radius: 50%;
        overflow: hidden;
        margin-right: 10px;
        
        img {
          width: inherit;
          height: inherit;
          object-fit: cover;
        }
      }
    }
  }
}