.preview-details-wrapper {
    font-family: Noto Sans;
    z-index: 1;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    backdrop-filter: blur(3px);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .preview-details-maindiv {
    width: 708px;
    height: 570px;
    overflow: auto;
    padding-bottom: 20px;
    flex-shrink: 0;
    border-radius: 12px;
    border: 1px solid #98b3c1;
    background: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
  
    .preview-details-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      height: 22px;
      padding: 40px 0 25px 0;
      .preview-details-heading {
        color: #000;
        font-family: Noto Sans;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        letter-spacing: 0.5px;
        padding: 20px 0px;
        position: relative;
        left: 40px;
      }
      .preview-details-header-icons {
        display: flex;
        position: relative;
        right: 40px;
        gap: 30px;
      }
    }
  
    .preview-details-content {
      width: 500px;
      display: flex;
      justify-content: center;
      flex-direction: column;
  
      .preview-details-title {
        color: var(--Dark, #194459);
        font-family: Noto Sans;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: 0.5px;
        padding: 15px 0px;

        span.heading {
          font-weight: bold;
          margin-right: 10px;
        }
      }
  
      p {
        color: var(--Dark, #194459);
        font-family: Noto Sans;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: 0.5px;
        padding: 15px 0px;
        width: 100%;
      }
  
      .preview-preview-details-img {
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
        gap: 12px;
        width: 490px;

        .card-body {
          width: 112px;
          height: 112px;
          background-color: lightslategray;
          border-radius: 8px;
          background: #d9d9d9;
          overflow: hidden;
          
          img {
            object-fit: cover;
            object-position: center;
            width: 100%;
            height: 100%;
          }
        }
      }
    }
  }
  