.news-wrapper {
  font-family: Noto Sans;
  z-index: 1;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  backdrop-filter: blur(3px);
  display: flex;
  justify-content: center;
  align-items: center;
}

.shownews-maindiv {
  width: 708px;
  height: 500px;
  border-radius: 12px;
  border: 1px solid #98b3c1;
  background: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: auto;
  padding: 20px 0;

  .show-newscontent-header {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;

    .show-newscontent-heading {
      color: #000;
      font-family: Noto Sans;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      letter-spacing: 0.5px;
      padding: 20px 0px;
    }

    .charmCross-icon {
      position: relative;
      right: 40px;
    }
  }

  .shownews-content {
    width: 500px;
    display: flex;
    justify-content: center;
    flex-direction: column;

    .news-topimage {
      width: 112px;
      height: 112px;
      border-radius: 8px;
      // background: #d9d9d9;
    }

    .shownews-title {
      color: var(--Dark, #194459);
      font-family: Noto Sans;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: 0.5px;
      padding: 20px 0px;
    }

    p {
      color: var(--Dark, #194459);
      font-family: Noto Sans;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: 0.5px;
      padding: 10px 0px;
      width: 100%;
    }

    h5 {
      color: #666;
      font-family: Noto Sans;
      font-size: 10px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: 1.5px;
      padding: 10px 0px;
    }

    .shownews-regionname {
      color: var(--Dark, #194459);
      font-family: Noto Sans;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: 0.5px;
      padding: 10px 0px;
    }
  }
}