.modal-wrapper {
  font-family: Noto Sans;
  display: flex;
  justify-content: center;
  z-index: 1;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  backdrop-filter: blur(3px);
  display: flex;
  justify-content: center;
  align-items: center;


  form {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    background-color: white;
    padding: 20px 10px;
    border-radius: 5px;
    width: 450px;
    z-index: 10;

    h4 {
      color: #000;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      letter-spacing: 0.5px;
      margin-bottom: 20px;
    }

    input, select {
      width: 292px;
      height: 40px;
      border-radius: 12px;
      border: 1px solid #98b3c1;
      background: #fff;
      margin-bottom: 20px;
      padding: 0 20px;
      font-size: 14px;
      font-weight: 400;
      letter-spacing: 1px;
    }

    select {
      padding: 10px;
      font-size: 13px;
    }

    input::placeholder {
      color: #98b3c1;
      font-size: 14px;
    }

    button {
      padding: 12px 32px;
      border-radius: 12px;
      background: #194459;
      color: #fff;
      text-align: center;
      font-size: 14px;
      font-weight: 700;
      letter-spacing: 1.25px;
      outline: none;
      border: none;
    }
  }
}