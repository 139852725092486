.personal-details-wrapper {
  display: flex;
  align-items: flex-start;
  gap: 28px;
  flex-direction: column;
  padding-left: 21px;

  h6 {
    color: var(--Dark, #194459);
    font-family: Noto Sans;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 1.5px;
    margin-bottom: 8px;
  }

  input {
    width: 392px;
    height: 40px;
    border-radius: 12px;
    border: 1px solid #98b3c1;
    background: #fff;
    padding-left: 10px;
  }

  .names {
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
  }

  .gender-labels {
    display: flex;
    gap: 20px;

    label {
      padding: 12px 32px;
      border-radius: 12px;
      border: 1px solid var(--Light, #5b686e);
      background: #fff;
      color: var(--Light, #5b686e);
      text-align: center;
      font-family: Noto Sans;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      letter-spacing: 1.25px;
      input {
        position: absolute;
        visibility: hidden;
      }
    }
  }
}
