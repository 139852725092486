@import "../../../../components/styles/mixin";
@import "../../../../components/styles/var";

.filters-wrapper {
  position: absolute;
  top: 0;
  right: 0;
  height: 250px;
  background-color: $background_white;
  border: 1px solid #D2D2D2;
  width: 320px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  .filters-header {
    position: absolute;
    top: 0;
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 40px;
    padding: 10px;
    border-bottom: 1px solid #D2D2D2;
  }

  .filters-body {
    position: absolute;
    top: 40px;
    width: 100%;
    height: 170px;
    display: flex;

    .filter-sidebar {
      width: 90px;
      height: 100%;
      border-right: 1px solid #D2D2D2;
      color: #5B686E;

      li {
        height: 25%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding: 0 10px;
        cursor: pointer;

        &.active {
          background-color: $background_blue;
          color: white;
        }
      }
    }

    .filter-main {
      width: 100%;
      height: 100%;
      overflow-y: auto;

      // &::-webkit-scrollbar {
      //   width: 4px;
      // }

      // &::-webkit-scrollbar-track {
      //   box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      // }

      // &::-webkit-scrollbar-thumb {
      //   background-color: darkgrey;
      // }

      li {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 6px 10px;
        font-size: 15px;
        font-weight: 500;

        &.active {
          background-color: $background_blue;
          color: white;
        }

      }
    }
  }

  .filters-footer {
    position: absolute;
    bottom: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 40px;
    padding: 0 10px;
    border-top: 1px solid #D2D2D2;

    .stats {
      width: 50%;
      text-align: left;
      font-size: 9px;

      span {
        line-height: 1;
      }
    }

    .button-wrapper {
      width: 50%;
      display: flex;
      justify-content: center;
      align-items: center;

      button {
        width: 70%;
        padding: 8px 10px;
        border-radius: 12px;
        outline: none;
        border: none;
        margin: 0 2px;
        font-size: 12px;
        font-weight: 700;
        cursor: pointer;

        &:nth-child(1) {
          background-color: white;
          color: $background_blue;
        }

        &:nth-child(2) {
          background-color: $background_blue;
          color: white;
        }
      }
    }
  }
}