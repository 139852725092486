.member-details-maindiv {
  background-color: white;
  margin: 20px;
  display: flex;
  border-radius: 12px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  padding: 29px 0 22px 20px;
  margin-bottom: 20px;
  min-height: 65vh;
  flex-direction: column;


  .back-arrow {
    display: flex;
    gap: 8px;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 40px;
    color: var(--Dark, #194459);
    font-family: Noto Sans;
    font-size: 16px;
    line-height: normal;
    letter-spacing: 0.5px;
    padding-left: 10px;
  }

  .main-content {
    display: flex;
  }

  .member-details-header {
    width: 20%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    border-right: 1px solid #98b3c1;

    .profile-div {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      width: 100%;
      margin-bottom: 10px;

      .profile-img {
        width: 150px;
        height: 150px;
        margin-bottom: 20px;
        border-radius: 50%;
        object-fit: cover;
        object-position: center;
      }

      .profile-heading {
        color: var(--Dark, #194459);
        font-family: Noto Sans;
        font-size: 16px;
        line-height: normal;
        letter-spacing: 0.5px;
        margin-bottom: 10px;
      }

      .profile-icons {
        display: flex;
        gap: 28px;
        margin-bottom: 18px;
      }

      .member-details-roles {
        width: 100%;
        padding-right: 19px;
        margin-top: 15px;

        label {
          border-radius: 12px;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          gap: 10px;
          width: 100%;
          height: 36px;
          padding: 8px 0 8px 12px;
          margin-bottom: 10px;
          font-family: Noto Sans;
          font-size: 14px;
          line-height: 20px;
        }
      }
    }
  }

  .saperator {
    border-top: 1px solid #bbb;
    width: 100%;
  }
}