@import "../../components/styles/mixin";
@import "../../components/styles/var";

.loginWrapper {
  width: 95%;
  height: 95vh;
  background-color: #e3f1f8;
  border-radius: 12px;
  // box-shadow: 0 0px 2px 0px;
  margin-top: 15px;
  display: flex;
  justify-content: center;
  font-family: "Noto Sans", sans-serif;
  form {
    padding: 40px 0 0 0;
    .inner-div {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      .logo {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 80px;
        width: 250px;
        // height: 200px;
      }

      .inputWrapper {
        width: 350px;
        height: 60px;
        background-color: #fff;
        border-radius: 12px;
        margin-bottom: 20px;
        display: flex;
        justify-content: center;
        flex-direction: column;
        gap: 8px;
        padding: 8px 0px 8px 20px;

        label {
          color: #666;
          font-family: Noto Sans;
          font-size: 10px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          letter-spacing: 1.5px;
        }

        .input-field {
          border: none;
          background: #fff;
          border: none;
          outline: none;
          width: 315px;
          margin-top: 3px;
          color: #000;
          font-family: Noto Sans;
          font-size: 15px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          letter-spacing: 0.5px;
        }
      }

      .button {
        width: 350px;
        height: 40px;
        background: #194459;
        border: none;
        border-radius: 12px;
        color: #e3f1f8;
        margin-bottom: 24px;
        text-align: center;
        font-size: 14px;
        font-weight: 700;
        letter-spacing: 1.25px;
      }

      .forgot {
        width: 100%;
        font-family: "Noto Sans" sans-serif;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: 0.4px;
        text-decoration-line: underline;
        margin-bottom: 12px;
        cursor: pointer;

        h4 {
          display: flex;
          float: right;
          color: var(--Dark, #194459);
          text-align: right;
          font-family: Noto Sans;
          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          letter-spacing: 0.4px;
          text-decoration-line: underline;
        }
      }

      .request {
        h3 {
          margin-top: 24px;
          letter-spacing: 1.25px;
          color: var(--Dark, #194459);
          text-align: center;
          font-family: Noto Sans;
          font-size: 14px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
          letter-spacing: 1.25px;
        }
      }
    }
  }
}
