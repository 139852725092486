.work-wrapper {
  overflow: auto; 
  max-height: 548px; 
  &::-webkit-scrollbar {
    width: 6px; 
  background: transparent;
  }
  margin-left: 21px;
 
    .work {
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      padding: 16px 0 40px;
      gap: 28px;
      border-bottom: 1px solid #98b3c1;
      h3 {
        color: var(--Dark, #194459);
        font-family: Noto Sans;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: 0.5px;
      }
     
      .work-names {
        display: flex;
        gap: 20px;
        flex-wrap: wrap;
        .role-heading {
          display: flex;
          gap: 4px;
  
          .role {
            height: 12px;
            width: 12px;
          }
        }
  
        .employment-heading {
          display: flex;
          gap: 4px;
  
        }
  
        h6 {
          color: var(--Dark, #194459);
          font-family: Noto Sans;
          font-size: 10px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          letter-spacing: 1.5px;
          margin-bottom: 8px;
        }
  
        input {
          width: 396px;
          height: 40px;
          flex-shrink: 0;
          border-radius: 12px;
          border: 1px solid #98b3c1;
          background: #fff;
          padding-left: 10px;
        }
      }
  
      .company {
        display: flex;
        gap: 20px;
        flex-wrap: wrap;
        .company-heading {
          display: flex;
          gap: 4px;
          .company {
            height: 12px;
            width: 12px;
          }
        }
  
        .location-heading {
          display: flex;
          gap: 4px;
          flex-wrap: wrap;
          .location {
            height: 12px;
            width: 12px;
          }
        }
  
        h6 {
          color: var(--Dark, #194459);
          font-family: Noto Sans;
          font-size: 10px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          letter-spacing: 1.5px;
          margin-bottom: 8px;
        }
  
        input {
          width: 396px;
          height: 40px;
          flex-shrink: 0;
          border-radius: 12px;
          border: 1px solid #98b3c1;
          background: #fff;
          padding-left: 10px;
        }
      }
  
      .dates {
        display: flex;
        gap: 20px;
        flex-wrap: wrap;
        h6 {
          color: var(--Dark, #194459);
          font-family: Noto Sans;
          font-size: 10px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          letter-spacing: 1.5px;
          margin-bottom: 8px;
        }
  
        input {
          width: 396px;
          height: 40px;
          flex-shrink: 0;
          border-radius: 12px;
          border: 1px solid #98b3c1;
          background: #fff;
          padding-left: 10px;
        }
      }
    }
  






///dsgbksdhgosadkgnksgfkjadbvlad


    .post-graduation {
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      padding: 16px 0 40px;
      gap: 28px;
      h3 {
        color: var(--Dark, #194459);
        font-family: Noto Sans;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: 0.5px;
      }
      .work-names {
        display: flex;
        gap: 20px;
  
        .role-heading {
          display: flex;
          gap: 4px;
  
          .role {
            height: 12px;
            width: 12px;
          }
        }
  
        .employment-heading {
          display: flex;
          gap: 4px;
  
          .employment {
            height: 12px;
            width: 12px;
          }
        }
  
        h6 {
          color: var(--Dark, #194459);
          font-family: Noto Sans;
          font-size: 10px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          letter-spacing: 1.5px;
          margin-bottom: 8px;
        }
  
        input {
          width: 396px;
          height: 40px;
          flex-shrink: 0;
          border-radius: 12px;
          border: 1px solid #98b3c1;
          background: #fff;
          padding-left: 10px;
        }
      }
  
      .company {
        display: flex;
        gap: 20px;
  
        .company-heading {
          display: flex;
          gap: 4px;
          .company {
            height: 12px;
            width: 12px;
          }
        }
  
        .location-heading {
          display: flex;
          gap: 4px;
  
          .location {
            height: 12px;
            width: 12px;
          }
        }
  
        h6 {
          color: var(--Dark, #194459);
          font-family: Noto Sans;
          font-size: 10px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          letter-spacing: 1.5px;
          margin-bottom: 8px;
        }
  
        input {
          width: 396px;
          height: 40px;
          flex-shrink: 0;
          border-radius: 12px;
          border: 1px solid #98b3c1;
          background: #fff;
          padding-left: 10px;
        }
      }
  
      .dates {
        display: flex;
        gap: 20px;
  
        h6 {
          color: var(--Dark, #194459);
          font-family: Noto Sans;
          font-size: 10px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          letter-spacing: 1.5px;
          margin-bottom: 8px;
        }
  
        input {
          width: 396px;
          height: 40px;
          flex-shrink: 0;
          border-radius: 12px;
          border: 1px solid #98b3c1;
          background: #fff;
          padding-left: 10px;
        }
      }
    }
  }
  