.campus-form,
.preview-details-maindiv,
.gallery-form,
.album-maindiv,
.show-notification-maindiv,
.new-notification-form,
.shownews-maindiv,
.news-update-form,
.showmember-maindiv,
.addmember-form,
.addlist-form {
    position: relative;
}

.btn-close {
    display: flex !important;
    position: absolute;
    right: 5px;
    top: 5px;
    width: 25px;
    height: 25px;
    border: 2px solid #194459;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

/* Responsive CSS */

@media screen and (max-width:1300px) {}

@media screen and (max-width:1199px) {

    .table-responsive,
    .data-list,
    .campus-data-list,
    .gallery-data-list,
    .contact-data-list {
        overflow-x: auto;
        min-height: auto !important;
        padding: 0px !important;
    }

    .data-list>table,
    .campus-data-list>table,
    .gallery-data-list>table,
    .contact-data-list>table {
        margin-bottom: 20px;
    }

    .data-list table>tbody>tr>td,
    .campus-data-list>table>tbody>tr>td,
    .gallery-data-list>table>tbody>tr>td,
    .contact-data-list>table>tbody>tr>td {
        /* min-width: 130px; */
        font-size: 14px;
        padding: 10px 0px;
        padding-right: 10px;
        white-space: nowrap;
    }

    .main-div,
    .campus-main-div,
    .gallery-main-div,
    .contact-main-div,
    .member-details-maindiv {
        margin: 0px;
        padding: 15px;
    }

    .head-wrapper {
        flex-wrap: wrap;
        padding: 0px !important;
    }

    .head-wrapper .rolemanagement-roles,
    .head-wrapper .youth-roles,
    .head-wrapper .announcement-roles,
    .head-wrapper .actions,
    .campus-roles,
    .gallery-roles,
    .notifications-roles,
    .listing-roles {
        width: auto !important;
    }

    .head-wrapper .rolemanagement-roles a,
    .head-wrapper .youth-roles a,
    .head-wrapper .announcement-roles a {
        padding-bottom: 10px;
    }

    .search-wrapper,
    .gallery-search-wrapper,
    .head-search-wrapper,
    .head-wrapper .rolemanagement-roles,
    .head-wrapper .youth-roles,
    .head-wrapper .announcement-roles,
    .notifications-roles,
    .campus-roles,
    .gallery-roles,
    .head-roles,
    .listing-roles {
        margin-bottom: 20px;
    }

    .search-wrapper .rectangle-group,
    .gallery-search-wrapper .rectangle-group,
    .head-search-wrapper .rectangle-group {
        width: 100% !important;
        margin-right: 0px !important;
    }

    .search-wrapper .rectangle-group input,
    .gallery-search-wrapper .rectangle-group input,
    .head-search-wrapper .rectangle-group input {
        width: 100%;
        border-radius: 12px;
    }

    .search-wrapper .add-btn,
    .gallery-search-wrapper .add-btn,
    .head-search-wrapper .rectangle-group .add-btn {
        padding: 10px 10px !important;
        margin-right: 0px !important;
        margin-left: 10px !important;
        width: 180px !important;
    }

    .head-wrapper .rolemanagement-roles a.active::after,
    .head-wrapper .youth-roles a.active::after,
    .head-wrapper .announcement-roles a.active::after {
        bottom: 0px;
    }

    .head-wrapper .actions .filter-options-wrapper {
        justify-content: flex-start;
        width: 100px;
        margin-right: 10px;
        margin-bottom: 20px;
        gap: 8px;
    }

    .head-wrapper .actions .filter-options-wrapper .download,
    .head-wrapper .actions .filter-options-wrapper .filters {
        width: 40px;
        height: 40px;
    }

    .campus-filter-options-wrapper {
        width: 40px;
        height: 40px;
        margin-right: 20px !important;
    }

    .filters-wrapper {
        top: 42px;
        right: auto;
        width: 280px;
        left: 47px;
    }

    .download-wrapper {
        top: 42px;
        left: 0px;
    }

    .campus-filter-options-wrapper .filters-wrapper {
        right: 0px;
        left: auto;
    }

    .filters-wrapper .filters-footer .button-wrapper {
        width: 60%;
    }

    .filters-wrapper .filters-footer .button-wrapper button {
        width: 100px;
    }



}

@media screen and (max-width:991px) {

    .data-list>.table {
        margin-bottom: 20px;
    }

    .tbody .head .regionCol {
        width: 100%;
    }

    .regionCol .td {
        width: 230px !important;
    }

    .regionCol .td span.name {
        width: 180px;
        display: block;
        font-size: 13px;
        line-height: 20px;
    }

    .regionCol>.td.active span.menu {
        top: 3px;
        left: -5px;
    }

    .head>.th {
        border-right: 2px solid rgb(217, 217, 217) !important;
    }

    .head>.th {
        padding: 10px 10px !important;
        width: 230px !important;
    }

    .head>.th span {
        display: block;
        min-width: 230px !important;
    }

    .alterOptions {
        left: 10px;
    }

    .member-details-maindiv .member-details-header {
        width: 40% !important;
        padding-right: 15px;
    }

    .personal-details-wrapper,
    .spiritual-details-wrapper,
    .family-details-wrapper,
    .education-wrapper,
    .work-wrapper,
    .license-wrapper,
    .contact-details-wrapper {
        width: 60% !important;
    }

    .personal-details-wrapper div,
    .personal-details-wrapper span,
    .spiritual-details-wrapper div,
    .work-wrapper div,
    .family-details-wrapper div,
    .education-wrapper div,
    .license-wrapper div,
    .contact-details-wrapper div,
    .main-content input {
        width: 100% !important;
    }

}

@media screen and (max-width:768px) {

    /* Header CSS */

    .navbar {
        height: 70px;
    }

    .navbar .inner_div {
        margin: 15px;
        position: relative;
        padding-right: 45px;
    }

    .navbar .inner_div a {
        display: inline-block;
    }

    .navbar .inner_div .horizontal_logo {
        width: 170px;
        height: auto;
        scale: none;
        margin-left: 0px;
    }

    .navbar .inner_div .bell {
        padding-top: 8px;
        margin-right: 12px;
    }

    .navbar .inner_div .profile {
        min-width: auto;
        border-radius: 50%;
        border: none;
    }

    .navbar .inner_div .profile .profile_logo {
        width: 38px;
        height: 38px;
        border-radius: 50%;
        margin: 0px;
    }

    .navbar .inner_div .profile h5 {
        display: none;
    }

    .profile-wrapper .profile-maindiv,
    .notification-maindiv,
    .edit-profile-maindiv {
        width: 310px !important;
        right: 55px !important;
        top: 60px !important;
        padding: 0px 15px !important;
        min-height: 300px;
        max-height: 500px;
        overflow: auto;
    }



    .profile-wrapper .profile-maindiv .profile-content .profile-data p {
        font-size: 13px;
    }

    .profile-wrapper .close,
    .edit-profile-wrapper .close {
        position: absolute !important;
        right: 70px !important;
        top: 80px !important;
        z-index: 5;
        left: auto;
    }

    .notification-wrapper .notification-maindiv {
        height: auto !important;
    }

    .edit-profile-wrapper .edit-profile-maindiv .edit-profile-content,
    .notification-content {
        margin: 0px 0px !important;
    }

    .notification-wrapper .notification-maindiv .notification-header {
        margin-top: 15px;
    }

    .navbar-toggler {
        display: flex !important;
        width: 28px;
        height: 30px;
        position: absolute;
        right: 0px;
        top: 4px;
        align-items: center;
        justify-content: center;
        cursor: pointer;
    }

    .navbar-toggler .navbar-toggler-line {
        height: 3px;
        background: #000;
        width: 100%;
    }

    .navbar-toggler .navbar-toggler-line::before,
    .navbar-toggler .navbar-toggler-line::after {
        content: '';
        width: 100%;
        border-top: 3px solid #000;
        position: absolute;
        top: 5px;
        right: 0px;
    }

    .navbar-toggler .navbar-toggler-line::after {
        top: auto;
        bottom: 5px;
    }

    .navbar .logo_div {
        width: 100%;
        background: #fff;
        position: fixed;
        top: 0px;
        height: 100%;
        z-index: 99;
        align-items: flex-start;
        padding: 50px 30px;
        overflow: auto;
        left: 100%;
        transition: .3s;
    }

    .navbar .logo_div.show {
        left: 0px;
    }

    .navbar .logo_div ul.navbar_icons {
        width: 100%;
        margin-bottom: 0px;
        list-style: none;
        flex-direction: column;
    }

    .navbar .logo_div ul.navbar_icons a span {
        display: inline-flex !important;
    }

    .navbar .logo_div ul.navbar_icons li {
        width: 100%;
        padding: 5px 0px;
    }

    .navbar .logo_div ul.navbar_icons a {
        position: relative;
        padding: 10px 0px;
        color: #194459;
        display: flex;
        width: 100%;
    }

    .navbar .logo_div ul.navbar_icons a div {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 10px;
    }

    .navbar .logo_div ul.navbar_icons a.active::after {
        height: 3px;
        background-color: #194459;
        bottom: 0px;
    }

    .navbar .logo_div .closeNavbar {
        display: flex;
        position: absolute;
        right: 20px;
        top: 20px;
        width: 22px;
        height: 22px;
        cursor: pointer;
    }

    .navbar .logo_div .closeNavbar::before,
    .navbar .logo_div .closeNavbar::after {
        content: '';
        border-top: 2px solid #000;
        width: 100%;
        transform: rotate(45deg);
        position: absolute;
        top: 10px;
    }

    .navbar .logo_div .closeNavbar::after {
        transform: rotate(-45deg) !important;
    }



    /* Main CSS */

    h4 {
        font-size: 16px;
    }

    .heading a {
        font-size: 14px;
    }

    .stats-counter,
    .youth-listing {
        flex-wrap: wrap;
        gap: 15px;
    }

    .stats-counter>div,
    .youth-listing>div {
        width: 100% !important;
    }

    .count-div .counter-card h4 {
        font-size: 14px;
    }

    .count-div .counter-card span {
        font-size: 24px;
    }

    .heading,
    .tbody>.head,
    .thead>.head {
        padding: 0 0px !important;
    }

    .youth-listing .data-list {
        padding: 10px 0px;
        min-height: auto !important;
        overflow: auto !important;
        height: 270px !important;
    }

    .youth-listing-wrapper {
        padding: 10px 15px !important;
    }

    .head-wrapper .rolemanagement-roles,
    .head-wrapper .youth-roles,
    .head-wrapper .announcement-roles {
        width: 100%;
        flex-wrap: wrap;
        justify-content: flex-start;
        align-items: flex-start;
        margin-bottom: 15px;
    }

    .head-wrapper .rolemanagement-roles a,
    .head-wrapper .youth-roles a,
    .head-wrapper .announcement-roles a {
        margin-right: 0px;
        width: auto;
        padding: 5px 0px;
        margin-bottom: 5px;
        padding-right: 10px;
        margin-right: 10px;
    }

    .head-wrapper .rolemanagement-roles a.active::after,
    .head-wrapper .youth-roles a.active::after,
    .head-wrapper .announcement-roles a.active::after {
        height: 2px;
        bottom: -3px;
    }

    .search-wrapper {
        display: flex;
        width: 100%;
    }

    .head-wrapper>div {
        position: relative;
    }

    .head-wrapper .actions .filter-options-wrapper {
        width: 180px;
    }

    .head-wrapper>div,
    .head-wrapper .rolemanagement-roles,
    .head-wrapper .youth-roles,
    .head-wrapper .announcement-roles,
    .head-wrapper .actions,
    .campus-roles,
    .gallery-roles,
    .notifications-roles,
    .listing-roles {
        width: 100% !important;
    }

    .campus-roles {
        min-height: 40px !important;
        margin-bottom: 10px !important;
        padding-right: 60px !important;
    }

    .campus-filter-options-wrapper {
        position: absolute !important;
        right: 0px;
        top: -50px !important;
        width: 40px;
        height: 40px;
        margin-right: 0px !important;
    }

    .album-maindiv,
    .gallery-wrapper .gallery-form,
    .campus-wrapper .campus-form,
    .new-notification-form,
    .news-update-form,
    .modal-wrapper form,
    .showmember-maindiv,
    .shownews-maindiv,
    .show-notification-wrapper .show-notification-maindiv,
    .preview-details-maindiv,
    .addlist-form {
        width: 96% !important;
        max-height: 96% !important;
        padding: 40px 15px !important;
    }

    .album-header,
    .gallery-header,
    .campus-header,
    .new-notification-header,
    .newsupdate-header,
    .showmember-header,
    .show-newscontent-header,
    .show-notification-header,
    .preview-details-header,
    .addlist-header {
        height: auto !important;
        padding: 0px !important;
        margin-bottom: 15px !important;
    }

    .album-header span,
    .gallery-header span,
    .campus-header span,
    .new-notification-header span,
    .newsupdate-header span,
    .showmember-header span,
    .show-newscontent-header span,
    .preview-details-header span,
    .addlist-header span {
        display: none;
    }

    .album-header .album-heading,
    .gallery-header .gallery-heading,
    .campus-header .campus-heading,
    .new-notification-header .new-notification-heading,
    .newsupdate-header .newsupdate-heading,
    .showmember-header h4,
    .show-newscontent-header .show-newscontent-heading,
    .preview-details-heading,
    .addlist-heading {
        padding: 0px 0px !important;
        left: 0px !important;
    }

    .album-header .album-header-icons,
    .gallery-header .save,
    .campus-header .save,
    .new-notification-header .charmCross-icon,
    .newsupdate-header .charmCross-icon,
    .showmember-header .img-div,
    .show-newscontent-header .charmCross-icon,
    .preview-details-header .preview-details-header-icons,
    .addlist-header .save {
        right: 0px !important;
        gap: 15px !important;
        letter-spacing: 0px !important;
    }

    .album-content,
    .gallery-content,
    .campus-content,
    .campus-wrapper .campus-form .campus-inputs .img-wrapper,
    .new-notification-content,
    .newsupdate-content,
    .showmember-content,
    .showmember-maindiv .showmember-content,
    .shownews-maindiv .shownews-content,
    .show-notification-maindiv,
    .show-notification-content,
    .preview-details-content,
    .addlist-content {
        width: 100% !important;
    }

    .album-maindiv .album-header .album-header-icons img {
        width: 20px;
    }

    .select-audience-type {
        gap: 6px;
    }

    .select-audience-type>label {
        width: 25%;
    }

    .select-audience-type>label,
    .type-of-update>label,
    .gender-labels>label {
        padding: 6px 12px !important;
        font-size: 13px !important;
        letter-spacing: 0px !important;
    }

    .gallery-wrapper .gallery-form .gallery-inputs .img-wrapper,
    .campus-wrapper .campus-form .campus-inputs .img-wrapper {
        flex-wrap: wrap;
        gap: 10px;
        width: 100%;
    }

    .gallery-wrapper .gallery-form .gallery-inputs .img-wrapper>label,
    .campus-wrapper .campus-form .campus-inputs .img-wrapper label {
        width: 100px !important;
        height: 100px !important;
    }

    .modal-wrapper form input,
    .modal-wrapper form select,
    .select-wrapper select {
        width: 100% !important;
    }

    .select-wrapper span {
        position: absolute;
        right: 15px;
        top: 6px;
        padding: 0px;
    }

    .news-button,
    .new-notification-button {
        justify-content: flex-start;
    }

    .member-details-maindiv .member-details-header .profile-div .member-details-roles {
        padding-right: 0px;
    }

    .member-details-maindiv .main-content {
        flex-wrap: wrap;
    }

    .profile-wrapper .profile-maindiv .profile-content {
        margin: 0px 0px;
    }

    .family-details-wrapper .siblings {
        width: 100% !important;
        flex-wrap: nowrap;
    }

    .siblings>div {
        width: 50%;
    }

    .personal-details-wrapper .gender-labels label input {
        width: auto !important;
    }


}

@media screen and (max-width:576px) {

    .member-details-maindiv .member-details-header,
    .personal-details-wrapper,
    .spiritual-details-wrapper,
    .family-details-wrapper,
    .education-wrapper,
    .main-content div {
        padding: 0px;
        width: 100% !important;
        margin: 0px;
    }

    .member-details-maindiv .main-content {
        flex-wrap: wrap;
    }

    .member-details-maindiv .member-details-header,
    .personal-details-wrapper {
        width: 100%;
        border: none;
        padding-right: 0px;
    }

    .head-wrapper .rolemanagement-roles a,
    .head-wrapper .youth-roles a,
    .head-wrapper .announcement-roles a {
        width: 100%;
        padding-right: 0px;
        margin-right: 0px;
    }
}

@media screen and (max-width:350px) {}