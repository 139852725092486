.showmember-wrapper {
  font-family: Noto Sans;
  z-index: 1;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  backdrop-filter: blur(3px);
  display: flex;
  justify-content: center;
  align-items: center;
}

.showmember-maindiv {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
  padding: 40px 10px;
  border-radius: 12px;
  width: 500px;
  // height: 354px;
  z-index: 10;

  .showmember-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 20px;
    width: 100%;

    h4 {
      position: relative;
      left: 20px;
      color: var(--Dark, #194459);
      font-family: Noto Sans;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      letter-spacing: 0.5px;
    }

    .img-div {
      position: relative;
      right: 20px;
    }
  }

  .showmember-content {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    gap: 20px;
    width: 70%;

    .names {
      display: flex;
      justify-content: flex-start;
      gap: 10px;
    }

    .members-details {
      color: var(--Dark, #194459);
      font-family: Noto Sans;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: 0.5px;
      width: 100%;
      display: flex;
      justify-content: space-between;

      span:nth-child(1) {
        width: 150px;
        font-weight: 600;
      }
    }
  }
}