.alterOptions {
    position: absolute;
    left: 20px;
    background-color: white;
    border-radius: 12px;
    padding: 5px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 3px 15px;
    // width: 100px;

    li {
        width: 100%;
        padding: 5px 10px;
        display: flex;
        justify-content: flex-start;
        font-size: 14px;
        cursor: pointer;

        span>img {
            width: 18px;
            margin-right: 10px;
        }
    }
}