.album-wrapper {
  font-family: Noto Sans;
  z-index: 1;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  backdrop-filter: blur(3px);
  display: flex;
  justify-content: center;
  align-items: center;
}

.album-maindiv {
  width: 708px;
  max-height: 500px;
  padding-bottom: 20px;
  border-radius: 12px;
  border: 1px solid #98b3c1;
  background: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: auto;

  .album-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 22px;
    padding: 40px 0 25px 0;

    .album-heading {
      color: #000;
      font-family: Noto Sans;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      letter-spacing: 0.5px;
      padding: 20px 0px;
      position: relative;
      left: 40px;
    }

    .album-header-icons {
      display: flex;
      position: relative;
      right: 40px;
      gap: 30px;
    }
  }

  .album-content {
    width: 500px;
    display: flex;
    justify-content: center;
    flex-direction: column;

    .album-title {
      color: var(--Dark, #194459);
      font-family: Noto Sans;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: 0.5px;
      padding: 15px 0px;
    }

    p {
      color: var(--Dark, #194459);
      font-family: Noto Sans;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: 0.5px;
      padding: 15px 0px;
      width: 100%;
    }

    .preview-album-img {
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;
      gap: 12px;
      
      label {
        overflow: hidden;
        img {
          object-fit: cover;
          object-position: center;
          width: 100%;
          height: 100%;
          border: none;
          outline: none;
        }
      }

      .card-body {
        width: 112px;
        height: 112px;
        background-color: lightslategray;
        border-radius: 8px;
        background: #d9d9d9;
      }
    }
  }
}