@import "../../components/styles/mixin";
@import "../../components/styles/var";

.contact-main-div {
  background-color: white;
  margin: 20px;
  display: flex;
  flex-direction: column;
  border-radius: 12px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  margin-bottom: 20px;
  min-height: 65vh;
  z-index: 1;
}

.head-wrapper {
  display: flex;
  justify-content: space-between;
  padding-top: 20px;
  padding-left: 30px;
  padding-bottom: 30px;
  padding-right: 20px;

  .head-roles {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 50%;

    h3 {
      color: black;
    }
  }
}

.head-search-wrapper {
  display: flex;

  .text-area {
    outline: none;
    height: 36px;
    border: none;
    font-family: Noto Sans;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 1.5px;
  }
}

.contact-data-list {
  color: #000;
  font-family: Noto Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 0.5px;
  padding: 0 30px;
  min-height: 50vh;
}

.contact-data-list>table {
  width: 100%;
  table-layout: auto;
}

table>thead {
  color: #666;
  font-family: Noto Sans;
  font-size: 10px;
}

thead>.head {
  padding: 0 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 1.5px;
}

.head>th {
  padding-bottom: 15px;
  color: #666;
  border-bottom: 2px solid #d9d9d9;
  font-family: Noto Sans;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 1.5px;
  text-align: left;
}

.copy-icon {
  // position: relative;
  margin-left: 5px;
  cursor: pointer;
  // margin-top: 5px;
  // top: 4px;
  // left: 5px;
}

table>tbody>tr.thisTr>td {
  padding: 12px 0;
}




.search-icon {
  width: 20px;
  height: 20px;
  margin: 0 10px;
}

.rectangle-group {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-radius: 12px;
  border: 1px solid #98b3c1;
  background: #fff;
  width: 274px;
  height: 40px;
  margin-right: 12px;
}

.group {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.fallback {
  width: 100%;
  height: 30vh;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
}