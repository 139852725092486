.profile-wrapper {
  font-family: Noto Sans;
  z-index: 2;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  // position: relative;

  .close {
    position: relative;
    left: 340px;
    top: 47px;
    z-index: 5;
  }

  .profile-maindiv {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    width: 396px;
    border-radius: 8px;
    border: 1px solid #becfd8;
    background: #fff;
    position: relative;
    right: 26px;
    top: 26px;

    .profile-content {
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      margin: 0px 30px;

      ///HEADER

      .profile-header {
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: center;
        margin-top: 20px;
        margin-bottom: 20px;

        .profile-heading {
          color: #000;
          font-family: Noto Sans;
          font-size: 16px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
          letter-spacing: 0.5px;
          display: flex;
          gap: 10px;
        }

        .edit-icon {
          width: 20px;
          height: 20px;
          flex-shrink: 0;
        }

        .charmCross-icon {
          position: relative;
          right: 0px;
        }
      }

      .profile-pic {
        margin-bottom: 20px;
        position: relative;
        height: 100px;
        width: 100px;
        border-radius: 50%;
        overflow: hidden;

        .base-image {
          object-fit: cover;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: inherit;
          height: inherit;
        }

        .overlay-image2 {
          background-color: #5b686e;
          text-align: center;
          position: absolute;
          bottom: 0;
          width: 100%;
          height: 20px;
        }
      }

      .profile-data {
        width: 100%;
        margin-bottom: 10px;


        .profile-name {
          color: var(--Dark, #194459);
          font-family: Noto Sans;
          font-size: 16px;
          font-weight: 400;
          letter-spacing: 0.5px;
          text-align: center;
        }

        p {
          text-overflow: ellipsis;
          color: var(--Dark, #194459);
          font-family: Noto Sans;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          letter-spacing: 0.5px;
        }

        .showMore {
          color: rgb(91, 91, 255);
        }


      }


      .test {
        width: 100%;
        display: flex;
        justify-content: center;
        /* align-items: center; */
        flex-direction: column;

        .profile-roles {
          display: flex;
          gap: 20px;
          margin-bottom: 20px;
          align-items: baseline;

          .profile-role-heading {
            color: var(--Light, #5b686e);
            font-family: Noto Sans;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            letter-spacing: 0.5px;
          }

          .profile-role-label {
            display: inline-flex;
            padding: 12px 32px;
            justify-content: center;
            align-items: center;
            gap: 10px;
            border-radius: 12px;
            border: 1px solid var(--Dark, #194459);
            background: #fff;
          }
        }

        .profile-region-name {
          margin-bottom: 20px;
          color: var(--Light, #5b686e);
          font-family: Noto Sans;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          letter-spacing: 0.5px;
        }

        .profile-diocese-name {
          margin-bottom: 20px;
          color: var(--Light, #5b686e);
          font-family: Noto Sans;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          letter-spacing: 0.5px;
        }
      }

      //BUTTON CSS
      .button-lable {
        display: inline-flex;
        padding: 12px 32px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        border-radius: 12px;
        background: #ae0c0c;
        margin-bottom: 20px;

        .logout {
          background-color: #ae0c0c;
          border: none;
          outline: none;
          color: #fff;
          text-align: center;
          font-family: Noto Sans;
          font-size: 14px;
          font-weight: 700;
          letter-spacing: 1.25px;
        }
      }

      //content end
    }
  }
}