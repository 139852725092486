.contact-details-wrapper {
  display: flex;
  gap: 28px;
  flex-direction: column;
  padding-left: 21px;
 

  h6 {
    color: var(--Dark, #194459);
    font-family: Noto Sans;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 1.5px;
    margin-bottom: 8px;
  }



  .email {
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
    .email-heading {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      gap: 4px;

      .email {
        height: 12px;
        width: 12px;
      }
    }
    input {
      width: 396px;
      height: 40px;
      border-radius: 12px;
      border: 1px solid #98b3c1;
      background: #fff;
      padding-left: 10px;
    }
  }

  .mobile-no {
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
    .mobile-no-heading {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      gap: 4px;

      .mobile-no {
        height: 12px;
        width: 12px;
      }
    }

    .whatsapp-heading {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      gap: 4px;
      .whatsapp {
        height: 12px;
        width: 12px;
      }
    }
    input {
      width: 396px;
      height: 40px;
      border-radius: 12px;
      border: 1px solid #98b3c1;
      background: #fff;
      padding-left: 10px;
    }
  }

  .alternate-mobile-no {
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
    .alternate-mobile-no-heading {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      gap: 4px;

      .mobile {
        height: 12px;
        width: 12px;
      }
    }
    input {
      width: 396px;
      height: 40px;
      border-radius: 12px;
      border: 1px solid #98b3c1;
      background: #fff;
      padding-left: 10px;
    }
  }

  .address {
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
    flex-direction: column;
    .address-heading {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      gap: 4px;

      .address-icon {
        height: 12px;
        width: 12px;
      }
    }

    .address-input {
      width: 812px;
      height: 40px;
      border-radius: 12px;
      border: 1px solid #98b3c1;
      background: #fff;
      padding-left: 10px;
    }

    .postcode {
      display: flex;
      gap: 20px;
      flex-wrap: wrap;
      input {
        width: 396px;
        height: 40px;
        border-radius: 12px;
        border: 1px solid #98b3c1;
        background: #fff;
        padding-left: 10px;
      }


    }
  }
}
