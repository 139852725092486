.spiritual-details-wrapper {
  display: flex;
  align-items: flex-start;
  gap: 28px;
  flex-direction: column;
  padding-left: 21px;

  .names {
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
    .names-heading {
      display: flex;
      justify-content: flex-start;
      gap: 4px;

      .solid-cross {
        height: 12px;
        width: 12px;
      }
    }
  }

  h6 {
    color: var(--Dark, #194459);
    font-family: Noto Sans;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 1.5px;
    margin-bottom: 8px;
  }

  input {
    width: 396px;
    height: 40px;
    flex-shrink: 0;
    border-radius: 12px;
    border: 1px solid #98b3c1;
    background: #fff;
    padding-left: 10px;
  }
}
