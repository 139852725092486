.edit-profile-wrapper {
  font-family: Noto Sans;
  z-index: 1;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;

  .close {
    position: relative;
    left: 340px;
    top: 47px;
    z-index: 5;
  }

  .edit-profile-maindiv {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    width: 396px;
    border-radius: 8px;
    border: 1px solid #becfd8;
    background: #fff;
    position: relative;
    right: 26px;
    top: 26px;

    .edit-profile-content {
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      margin: 0px 30px;

      ///HEADER

      .edit-profile-header {
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: center;
        margin-top: 20px;
        margin-bottom: 20px;

        .edit-profile-heading {
          color: #000;
          font-family: Noto Sans;
          font-size: 16px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
          letter-spacing: 0.5px;
          display: flex;
          gap: 10px;
        }

        .charmCross-icon {
          position: relative;
          right: 0px;
        }
      }

      .edit-profile-pic {
        margin-bottom: 20px;
        position: relative;
        height: 100px;
        width: 100px;
        border-radius: 50%;
        overflow: hidden;

        .base-image {
          object-fit: cover;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: inherit;
          height: inherit;
        }

        .overlay-image2 {
          background-color: #5b686e;
          text-align: center;
          position: absolute;
          bottom: 0;
          width: 100%;
          height: 20px;
        }
      }

      .edit-profile-data {
        width: 100%;
        .edit-profile-name {
          width: 100%;
          height: 40px;
          color: var(--Dark, #194459);
          font-family: Noto Sans;
          font-size: 16px;
          font-weight: 400;
          letter-spacing: 0.5px;
          text-align: center;
          border-radius: 12px;
          border: 1px solid #98b3c1;
          background: #fff;
          margin-bottom: 12px;
        }
      }
      textarea {
        width: 100%;
        height: 116px;
        border-radius: 12px;
        border: 1px solid #98b3c1;
        background: #fff;
        margin-bottom: 12px;
        overflow: hidden;
        color: var(--Dark, #194459);
        font-family: Noto Sans;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: 0.5px;
        padding: 10px;
      }

      //BUTTON CSS
      .button-lable {
        display: inline-flex;
        padding: 12px 32px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        border-radius: 12px;
        background: var(--Dark, #194459);
        margin-bottom: 20px;

        .save {
          background: var(--Dark, #194459);
          color: #fff;
          outline: none;
          border: none;
          text-align: center;
          font-family: Noto Sans;
          font-size: 14px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
          letter-spacing: 1.25px;
        }
      }
    }
  }
}
