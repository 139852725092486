@import "../../../../components/styles/mixin";
@import "../../../../components/styles/var";

.download-wrapper {
  position: absolute;
  top: 0;
  left: -20px;
  background-color: $background_white;
  border: 1px solid #D2D2D2;
  width: 100px;
  border-radius: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 15px;

  ul.download-options li {
    display: flex;
    justify-content: flex-start;
    padding: 6px 0;
  }

  ul.download-options li span {
    margin-left: 15px;
  }
}