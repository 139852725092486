.show-notification-wrapper {
  font-family: Noto Sans;
  z-index: 1;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  backdrop-filter: blur(3px);
  display: flex;
  justify-content: center;
  align-items: center;
}

.show-notification-maindiv {
  width: 708px;
  height: 500px;
  border-radius: 12px;
  border: 1px solid #98b3c1;
  background: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: auto;
  padding: 20px 0;

  .show-notification-content {
    width: 500px;
    display: flex;
    justify-content: center;
    flex-direction: column;

    .show-notification-header {
      text-align: center;

      .show-notification-heading {
        color: #000;
        font-family: Noto Sans;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        letter-spacing: 0.5px;
        padding: 20px 0px;
      }
    }

    .show-notification-topimage {
      width: 112px;
      height: 112px;
      border-radius: 8px;
      // background: #d9d9d9;
      object-fit: cover;
    }

    .show-notification-title {
      color: var(--Dark, #194459);
      font-family: Noto Sans;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: 0.5px;
      padding: 20px 0px;
    }

    p {
      color: var(--Dark, #194459);
      font-family: Noto Sans;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: 0.5px;
      padding: 10px 0px;
      width: 100%;
    }

    h5 {
      color: #666;
      font-family: Noto Sans;
      font-size: 10px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: 1.5px;
      padding: 10px 0px;
    }

    .show-notification-regionname {
      color: var(--Dark, #194459);
      font-family: Noto Sans;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: 0.5px;
      padding: 10px 0px;
    }
  }
}