.addlist-wrapper {
  font-family: Noto Sans;
  z-index: 1;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  backdrop-filter: blur(3px);
  display: flex;
  justify-content: center;
  align-items: center;

  .addlist-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: white;
    padding: 30px 50px;
    border-radius: 12px;
    flex-shrink: 0;
    z-index: 10;

    .addlist-header {
      display: flex;
      width: 100%;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 20px;

      .addlist-heading {
        letter-spacing: 0.5px;
        font-size: 16px;
        position: relative;
        left: 25px;
      }

      .save {
        position: relative;
        right: -15px;
        color: var(--Dark, #194459);
        font-family: Noto Sans;
        font-size: 14px;
        font-weight: 700;
        letter-spacing: 1.25px;
        cursor: pointer;
        border: none;
        outline: none;
        background-color: white;
      }
    }

    .select-audience-type {
      display: flex;
      gap: 9px;
      margin-bottom: 20px;

      label {
        padding: 12px 32px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        border-radius: 12px;
        border: 1px solid var(--Light, #5b686e);
        background: #fff;
        color: var(--Light, #5b686e);
        text-align: center;
        font-family: Noto Sans;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        letter-spacing: 1.25px;
      }

      .audience-input {
        position: absolute;
        visibility: hidden;
      }
    }

    .addlist-inputs {
      display: flex;
      justify-content: start;
      flex-direction: column;

      input {
        border-radius: 12px;
        border: 1px solid #98b3c1;
        background: #fff;
        height: 40px;
        margin-bottom: 20px;
        padding: 0px 10px;
      }

      .select-wrapper {
        position: relative;


        select {
          padding: 0px 10px;
          border-radius: 12px;
          border: 1px solid #98b3c1;
          background: #fff;
          width: 499px;
          height: 40px;
          flex-shrink: 0;
          margin-bottom: 20px;
          color: #98b3c1;
          font-family: Noto Sans;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          letter-spacing: 0.5px;
          -moz-appearance: none;
          /* Firefox */
          -webkit-appearance: none;
          /* Safari and Chrome */
          appearance: none;
        }

        span {
          position: absolute;
          right: 10px;
          top: 10px;
          padding: 0px 20px 8px 0;
        }
      }

    }

    .addlist-inputs input::placeholder {
      color: #98b3c1;
      font-family: Noto Sans;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: 0.5px;
    }
  }
}