.gallery-wrapper {
  font-family: Noto Sans;
  z-index: 1;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  backdrop-filter: blur(3px);
  display: flex;
  justify-content: center;
  align-items: center;

  .gallery-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: white;
    padding: 40px 10px;
    border-radius: 12px;
    width: 708px;
    height: 680px;
    z-index: 10;
    overflow: auto;

    .gallery-header {
      display: flex;
      width: 100%;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 20px;

      .gallery-heading {
        position: relative;
        left: 20px;
        color: #000;
        font-family: Noto Sans;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        letter-spacing: 0.5px;
      }

      .save {
        cursor: pointer;
        position: relative;
        right: 35px;
        color: var(--Dark, #194459);
        text-align: center;
        font-family: Noto Sans;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        letter-spacing: 1.25px;
        border: none;
        outline: none;
        background-color: inherit;
      }
    }

    label {
      padding: 12px 32px;
      border-radius: 12px;
      border: 1px solid var(--Light, #5b686e);
      background: #fff;
      color: var(--Light, #5b686e);
      text-align: center;
      font-family: Noto Sans;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      letter-spacing: 1.25px;
    }

    .select-audience {
      color: #666;
      font-family: Noto Sans;
      font-size: 10px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: 1.5px;
      margin-bottom: 12px;
    }

    .select-audience-type {
      display: flex;
      gap: 9px;
      margin-bottom: 20px;
    }

    .audience-input {
      position: absolute;
      visibility: hidden;
    }

    .gallery-inputs {
      display: flex;
      justify-content: start;
      flex-direction: column;

      input,
      select {
        border-radius: 12px;
        border: 1px solid #98b3c1;
        background: #fff;
        height: 40px;
        margin-bottom: 20px;
        padding: 09px 5px;

        option {
          height: 40px;
          padding: 20px;
        }
      }

      textarea {
        border-radius: 12px;
        border: 1px solid #98b3c1;
        background: #fff;
        margin-bottom: 20px;
        height: 120px;
        width: 100%;
        padding: 9px 10px;
        font-family: Noto Sans;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: 1.5px;
      }

      .img-wrapper {
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
        gap: 13px;
        width: 490px;

        .card-body {
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 8px;
          flex-direction: column;
          width: 112px;
          height: 112px;
          border-radius: 8px;
          border: 1px solid var(--Dark, #194459);
          position: relative;

          .add-img {
            width: 24px;
            height: 24px;
          }

          .add-input {
            position: absolute;
            visibility: hidden;
          }

          span {
            width: 96px;
            background-color: #fff;
            color: var(--Dark, #194459);
            text-align: center;
            font-family: Noto Sans;
            font-size: 13px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            letter-spacing: 1.25px;
            border: none;
          }
        }

        .card-body2 {
          padding: 0;
          width: 112px;
          height: 112px;
          background-color: #5b686e;
          background-size: contain;
          background-repeat: no-repeat;
          overflow: hidden;
          
          img {
            object-fit: cover;
            object-position: center;
            width: 100%;
            height: 100%;
          }
        }
      }
    }

    .gallery-inputs input::placeholder,
    .gallery-inputs textarea::placeholder {
      color: #98b3c1;
      font-family: Noto Sans;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: 0.5px;
    }

    .gallery-inputs input {
      padding: 10px;
    }
  }
}