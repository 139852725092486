.newsModal-wrapper {
  font-family: Noto Sans;
  z-index: 1;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  backdrop-filter: blur(3px);
  display: flex;
  justify-content: center;
  align-items: center;

  .news-update-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: white;
    padding: 40px 10px;
    border-radius: 12px;
    width: 708px;
    height: 630px;
    z-index: 10;
    overflow: auto;
    .newsupdate-heading {
      color: #000;
      font-family: Noto Sans;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      letter-spacing: 0.5px;
    }
    .newsupdate-header {
      display: flex;
      width: 100%;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 20px;
    }

    .charmCross-icon {
      position: relative;
      right: 15px;
    }
    .update-heading {
      color: #666;
      font-family: Noto Sans;
      font-size: 10px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: 1.5px;
      margin-bottom: 12px;
    }

    .type-of-update {
      display: flex;
      gap: 10px;
      margin-bottom: 20px;
    }

    label {
      padding: 12px 32px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      border-radius: 12px;
      border: 1px solid var(--Light, #5b686e);
      background: #fff;
      color: var(--Light, #5b686e);
      text-align: center;
      font-family: Noto Sans;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      letter-spacing: 1.25px;
    }

    .update-input {
      position: absolute;
      visibility: hidden;
    }

    .select-audience {
      color: #666;
      font-family: Noto Sans;
      font-size: 10px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: 1.5px;
      margin-bottom: 12px;
    }

    .select-audience-type {
      display: flex;
      gap: 9px;
      margin-bottom: 20px;
    }

    .audience-input {
      position: absolute;
      visibility: hidden;
    }

    .news-update-inputs {
      display: flex;
      justify-content: start;
      flex-direction: column;
      input, select {
        border-radius: 12px;
        border: 1px solid #98b3c1;
        background: #fff;
        height: 40px;
        margin-bottom: 20px;
        padding: 9px 20px;
        font-size: 16px;
      }

      select {
        padding: 10px;
        font-size: 14px;
      }

      .body-wrapper {
        position: relative;

        textarea {
          border-radius: 12px;
          border: 1px solid #98b3c1;
          background: #fff;
          margin-bottom: 20px;
          height: 147px;
          width: 100%;
          padding: 9px 10px;
          color: #666;
          font-family: Noto Sans;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          letter-spacing: 1.5px;
        }

        span {
          position: absolute;
          width: 40px;
          height: 40px;
          background-color: #5b686e;
          border-radius: 8px;
          bottom: 35px;
          left: 10px;
          overflow: hidden;

          img {
            width: 100%;
            height: 100%;
            // right: 0;
            object-fit: cover; 
          }
        }
      }
    }

    .news-update-inputs input::placeholder,
    .news-update-inputs textarea::placeholder {
      color: #98b3c1;
      font-family: Noto Sans;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      letter-spacing: 0.5px;
    }
    .news-update-inputs input {
      padding: 10px;
    }

    .news-button {
      display: flex;
      justify-content: center;
      align-items: center;

      Button {
        padding: 12px 32px;
        border-radius: 12px;
        background: #194459;
        color: #fff;
        text-align: center;
        font-size: 14px;
        font-weight: 700;
        letter-spacing: 1.25px;
        outline: none;
        border: none;
      }
    }
  }
}
