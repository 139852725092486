.reset {
  width: 95%;
  height: 95vh;
  background-color: #e3f1f8;
  border-radius: 12px;
  // box-shadow: 0 0px 2px 0px;
  margin-top: 15px;
  display: flex;
  justify-content: center;
  .inner_reset_div {
    width: 50%;
    margin-top: 56px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 600px;
    margin: 0;

    .reset_logo {
      width: 250px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .request_for_password {
      h4 {
        font-family: "Noto Sans", sans-serif;
        margin-top: 70px;
        text-align: center;
        font-size: 24px;
        font-style: normal;
        font-weight: 100;
        line-height: normal;
        letter-spacing: 0.3px;
      }
    }
    .Email_ID_provided {
      h5 {
        font-family: "Noto Sans", sans-serif;
        color: #194459;
        text-align: center;
        font-style: normal;
        line-height: normal;
        margin-top: 20px;
        font-weight: 100;
      }
    }

    .inputWrapper {
      width: 350px;
      height: 60px;
      background-color: #fff;
      border-radius: 12px;
      margin-top: 20px;
      display: flex;
      justify-content: center;
      flex-direction: column;
      gap: 8px;
      padding: 8px 0px 8px 20px;

      label {
        color: #666;
        font-family: Noto Sans;
        font-size: 10px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: 1.5px;
      }

      .input-field {
        border: none;
        background: #fff;
        border: none;
        outline: none;
        width: 315px;
        margin-top: 3px;
        color: #000;
        font-family: Noto Sans;
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: 0.5px;
      }
    }
    .send_button {
      width: 350px;
      height: 40px;
      background: #194459;
      border: none;
      border-radius: 12px;
      color: #e3f1f8;
      margin-top: 15px;
      text-align: center;
      font-family: "Noto Sans" sans-serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      letter-spacing: 1.25px;
    }
    .connect {
      h3 {
        color: #194459;
        text-align: center;
        font-family: "Noto Sans", sans-serif;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        letter-spacing: 0.5px;
        margin-top: 40px;
      }
    }
  }
}
