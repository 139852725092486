@import "../styles/_var";
@import "../styles/mixin";

.navbar {
  background-color: $background_white;
  width: 100%;
  height: 140px;
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  float: right;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  z-index: 1;

  .inner_div {
    margin: 26px;

    .horizontal_logo {
      width: 152px;
      height: 32px;
      scale: 1.3;
      margin-left: 40px;
    }

    .profile {
      display: flex;
      float: right;
      min-width: 185px;
      border-radius: 16px;
      border: 1px solid #98b3c1;
      justify-content: flex-start;
      align-items: center;


      .profile_logo {
        width: 24px;
        height: 24px;
        border-radius: 24px;
        margin: 8px;
      }

      h5 {
        font-family: Noto Sans;
        font-size: 14px;
        font-weight: 700;
        letter-spacing: 1.25px;
        text-align: center;
        color: $background_blue;
        padding-left: 5px;
      }
    }
  }

  .logo_div {
    display: flex;
    width: 50%;
    justify-content: center;
    flex-flow: wrap;
    margin: auto;

    .closeNavbar{
      display: none;
    }

    ul.navbar_icons {
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      width: 100%;
      margin-bottom: 20px;
      list-style: none;

      a {
        position: relative;
        padding: 5px;
        display: block;
      }

      a.active::after {
        content: "";
        position: absolute;
        width: 100%;
        height: 7px;
        background-color: $background_blue;
        bottom: -15px;
        left: 0;
      }

      .navText{
        display: none;
      }
    }

    .navbar_logo {
      img {
        width: 24px;
        height: 24px;
      }
    }


  }
}

.bell {
  padding-top: 7px;
  margin-right: 20px;
  float: right;
}