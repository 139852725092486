@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:wght@400;700&display=swap');
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html,body{
    width: 100%;
    height: 100%;
    background: #e3f1f8;
    font-family: Noto Sans;
}

.pointer {
  cursor: pointer;
}

ul {
  list-style: none;
}

a {
  text-decoration: none;
}

.capitalize {
  text-transform: capitalize;
}