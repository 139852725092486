@import "../styles/_var";
@import "../styles/mixin";

.bottom {
  background-color: $background_blue;
  width: 100%;
  height: 54px;
  bottom: 0; 
  position: fixed;

  h6 {
    color: $background_white;
    font-family: Noto Sans;
    font-size: 10px;
    font-weight: 400;
    line-height: 14px;
    letter-spacing: 1.5px;
    text-align: center;
    height: 14px;
    padding-top: 20px;
    padding-bottom: 20px;
  }
}
