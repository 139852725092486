.wrapper {
  width: 100%;
  height: 100%;
  padding: 10px;
  padding-bottom: 60px;
}

.layout {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* height: 100vh; */
}

main {
  width: 100%;
  display: flex;
  justify-content: center;
}

a {
  text-decoration: none;
  color: inherit;
}

ul {
  list-style: none;
}

textarea {
  resize: none;
}

::-webkit-scrollbar {
  width: 10px;
  
}
 
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 30px;
}
 
::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  border-radius: 30px;
}